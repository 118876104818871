<template>
  <div id="editAddr" class="fixedMode">
    <div class="mainScroll">
      <div class="partBg">
        <div class="conBar">
          <span class="itemName">收件人</span>
          <div class="itemCon">
            <input
              type="text"
              class="itemInput"
              placeholder="请输入收件人"
              v-model="addrCon.name"
              maxlength="10"
            />
          </div>
        </div>
        <div class="conBar">
          <span class="itemName">手机号</span>
          <div class="itemCon">
            <input
              type="tel"
              class="itemInput"
              v-model="addrCon.mobile"
              maxlength="13"
              placeholder="请输入手机号"
            />
          </div>
        </div>
        <div class="conBar">
          <span class="itemName">所在地区</span>
          <div
            class="itemCon more"
            :class="{ nodata: !areaTxt }"
            @click="showSelectMask = true"
          >
            {{ areaTxt || "请选择" }}
          </div>
        </div>
        <div class="conBar">
          <span class="itemName">详细地址</span>
          <div class="itemCon">
            <textarea
              class="itemTextarea"
              rows="4"
              v-model="addrCon.detailAddress"
              placeholder="请输入详细地址"
              maxlength="100"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="partBg">
        <div class="conBar">
          <div class="itemCon">
            <input
              type="checkbox"
              class="checkboxInput"
              id="defaultAddr"
              v-model="addrCon.isDefault"
              :value="1"
            />
            <label for="defaultAddr" class="checkboxLabel">设为默认地址</label>
          </div>
        </div>
      </div>
      <button
        type="button"
        class="saveBtn"
        :disabled="submiting || loading"
        @click="saveEvent"
      >
        保存
      </button>
    </div>
    <areaLayer
      :showSelect="showSelectMask"
      :selectArea="selectAreaList"
      @close="closeAreaLayer"
    ></areaLayer>
  </div>
</template>

<script>
import areaLayer from "@/components/member/areaLayer";

export default {
  name: "editAddr",
  data() {
    return {
      addrId: this.$route.query.addrId, //  编辑状态id
      loading: false,
      showSelectMask: false, //  是否显示选择地区弹窗
      selectAreaList: [],
      areaTxt: "", //  所在地区 文字
      addrCon: {
        name: "", //  收货人姓名
        mobile: "", //  收货手机号
        provinceId: null, //  省id
        cityId: null, //  市id
        districtId: null, // 区id
        detailAddress: "", //  详细收货地址信息
        isDefault: 0, //  是否默认收货地址
        provinceName: "", //  省文字
        cityName: "", // 市文字
        districtName: "" /// 区文字
      },
      verifyArr: ["name", "mobile", "area", "detailAddress"],
      submiting: false
    };
  },
  components: {
    areaLayer
  },
  created() {
    if (!!this.addrId) {
      //  从编辑而来
      this.loading = true;
      this.request
        .get("/api/scm/user/address/detail", {
          id: this.addrId
        })
        .then((res) => {
          if (res) {
            this.addrCon = res;
            this.areaTxt = `${this.addrCon["provinceName"]} ${this.addrCon["cityName"]} ${this.addrCon["districtName"]}`;
            this.selectAreaList = [
              this.addrCon["provinceId"],
              this.addrCon["cityId"],
              this.addrCon["districtId"]
            ];
          }
          this.loading = false;
        });
    }
  },
  mounted() {},
  methods: {
    //  关闭选择地区
    closeAreaLayer(val) {
      if (!!val) {
        let txt = "",
          tempArr = [];
        val.forEach((item) => {
          txt += item.text + " ";
          tempArr.push(item.id);
        });
        this.areaTxt = txt;
        this.selectAreaList = tempArr;
        this.addrCon["provinceId"] = val[0].id;
        this.addrCon["provinceName"] = val[0].text;
        this.addrCon["cityId"] = val[1].id;
        this.addrCon["cityName"] = val[1].text;
        this.addrCon["districtId"] = val[2].id;
        this.addrCon["districtName"] = val[2].text;
      }
      this.showSelectMask = false;
    },
    //  保存地址
    saveEvent() {
      for (let i = 0; i < this.verifyArr.length; i++) {
        let checkTips = this.checkInput(this.verifyArr[i]);
        if (!!checkTips) {
          this.$toast({
            message: checkTips,
            position: "bottom"
          });
          return;
        }
      }
      //  强制“是否默认收货地址”类型
      this.addrCon["isDefault"] = Number(this.addrCon["isDefault"]);
      this.submiting = true;
      this.request
        .post("/api/scm/user/address/save", this.addrCon)
        .then((res) => {
          if (res) {
            this.$toast({
              message: "保存地址成功",
              position: "bottom"
            });
            //  如果编辑过地址把缓存的默认地址清空 让加载时重新获取
            this.$store.commit("UPDATEDEFAULTADDR", null);
            //  跳转返回上一页 一般从我的地址页而来
            this.$router.go(-1);
          }
          this.submiting = false;
        });
    },
    //  输入验证
    checkInput(item) {
      let tips = "",
        con = this.addrCon[item];
      switch (item) {
        case "name":
          if (!con) tips = "请输入收件人";
          break;
        case "mobile":
          if (!con) tips = "请输入手机号";
          break;
        case "area":
          if (!this.areaTxt) tips = "请选择所在地区";
          break;
        case "detailAddress":
          if (!con) tips = "请输入详细地址";
          break;
      }
      return tips;
    }
  }
};
</script>

<style lang="scss">
#editAddr {
  .van-picker__toolbar {
    height: 60px;
    .van-picker__title {
      font-size: 32px;
      line-height: 60px;
    }
    .van-picker__cancel,
    .van-picker__confirm {
      font-size: 30px;
    }
  }
  .van-picker-column {
    font-size: 30px;
  }
}
</style>


<style lang="scss" scoped>
.fixedMode {
  background: #efefef;
}
.partBg {
  background: #fff;
  margin-bottom: 14px;
}
.conBar {
  padding: 30px;
  display: flex;
  font-size: 30px;
  .itemName {
    line-height: 44px;
    width: 170px;
    padding-right: 15px;
    color: #666;
    display: block;
  }
  .itemCon {
    flex: 1;
    line-height: 44px;
    position: relative;
    .itemInput,
    .itemInput:focus {
      box-sizing: border-box;
      width: 100%;
      padding: 0;
      margin: 0;
      border: none;
      height: 44px;
      line-height: 2;
      font-size: 30px;
      display: block;
      color: #000;
      &::placeholder {
        color: #999;
      }
    }
    .itemTextarea,
    .itemTextarea:focus {
      box-sizing: border-box;
      width: 100%;
      padding: 0;
      margin: 0;
      border: none;
      resize: none;
      font-size: 30px;
      display: block;
      color: #000;
      font-family: inherit;
      line-height: 1.2;
      &::placeholder {
        color: #999;
      }
    }
    .checkboxLabel {
      display: flex;
      align-items: center;
      line-height: 30px;
      &::before {
        /* prettier-ignore */
        border: 1PX solid #999;
        border-radius: 50%;
        width: 28px;
        height: 28px;
        content: "";
        display: block;
        margin-right: 20px;
      }
    }
    #defaultAddr {
      &:checked + .checkboxLabel::before {
        background: #ff583d url(~@/assets/icons/gou.png) center / 100% 100%
          no-repeat;
        border-color: #ff583d;
      }
    }
    &.more {
      padding-right: 40px;
      background: url(~@/assets/icons/right-arrow.png) right center / auto 25px
        no-repeat;
    }
    &.nodata {
      color: #999;
    }
  }
  & + .conBar {
    border-top: 1px solid #dadbdd;
  }
}
.saveBtn {
  width: 690px;
  margin: 30px auto;
  display: block;
  height: 88px;
  border-radius: 88px;
  background: #f94215;
  color: #fff;
  font-size: 34px;
}
</style>